import React from "react"

import Header from "../components/Header"

export default function About() {
  return (
    <div style={{ color: `teal` }}>
      <Header headerText={`About Gatsby`} />
      <p>such wow, Very react</p>
    </div>
  )
}
